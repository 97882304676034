<template>
  <div>
    <div v-if="title == '联系我们'">
      <Map />
      <div class="pd_30">
        <el-row>
          <el-col :span="10"> <div class="detail_content" v-html="content"></div></el-col>
          <el-col :span="14" class="fl_r">
            <el-input placeholder="请输入主题" v-model="contactForm.title">
              <template slot="prepend">主题：</template>
            </el-input>
            <el-input
              placeholder="请输入联系人"
              v-model="contactForm.name"
              class="mt_20"
            >
              <template slot="prepend">联系人：</template>
            </el-input>
            <el-input
              placeholder="请输入电话"
              v-model="contactForm.telephone"
              class="mt_20"
            >
              <template slot="prepend">电话：</template>
            </el-input>
            <el-input
              placeholder="请输入留言"
              v-model="contactForm.content"
              class="mt_20"
            >
              <template slot="prepend">留言：</template>
            </el-input>
            <el-input
              placeholder="请输入验证码"
              v-model="inputCode"
              class="mt_20"
            >
              <template slot="prepend">验证码：</template>
              <template slot="append">
                <div @click="refreshCode">
                  <s-Identify :identifyCode="identifyCode"></s-Identify>
                </div>
              </template>
            </el-input>
            <el-button
              type="primary"
              class="primary-button mt_20"
              @click="handleSend()"
              >发送</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else-if="title == '荣誉资质'">
      <div class="flex justify-center mt_20 pb_60">
        <div class="flex">
          <div class="right_container bg_FFFFFF">
            <div class="mt_100" v-for="(item, index) in honorsList2" :key="index">
              <div v-if="index == 0" style="margin-top: -100px">
                <el-row >
                  <el-col  :span="10" style="padding: 60px 0">
                    <div class="text-center">
                      <div class="bg_horizontal">
<!--                        <el-image class="vertical_img" :src="item.img" :preview-src-list="[item.img]">-->
                        <el-image class="vertical_img" :src="item.img">
                        </el-image>
                      </div>
                      <div class="fc_595959 fz_18 mt_30 text-center">
                        {{ item.title }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="2"><div style="color: #fff">aaa</div></el-col>
                  <el-col :span="2" style="padding: 70px 13px 136px 30px; textalign: end">
                    <img
                        src="../../assets/images/home/honor.png"
                        class="honor_img"
                    />
                  </el-col>
                  <el-col :span="10" style="padding: 60px 0">
                    <div class="fz_36 fc_2A2A2A">
                      {{ item.title }}
                    </div>
<!--                    <div class="fz_18 fc_2A2A2A mt_50" v-html="item.data.content">-->
                    <div class="fz_18 fc_2A2A2A mt_50">
                      {{item.author}}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="(index % 2 == 0 && index !== 0)">
                <el-row>
                  <el-col  :span="10" style="padding: 60px 0">
                    <div class="text-center">
                      <div class="bg_horizontal">
<!--                        <el-image class="vertical_img" :src="item.img" :preview-src-list="[item.img]">-->
                        <el-image class="vertical_img" :src="item.img">
                        </el-image>
                      </div>
                      <div class="fc_595959 fz_18 mt_30 text-center">
                        {{ item.title }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="2"><div style="color: #fff">aaa</div></el-col>
                  <el-col :span="2" style="padding: 70px 13px 136px 30px; textalign: end">
                    <img
                        src="../../assets/images/home/honor.png"
                        class="honor_img"
                    />
                  </el-col>
                  <el-col :span="10" style="padding: 60px 0">
                    <div class="fz_36 fc_2A2A2A">
                      {{ item.title }}
                    </div>
<!--                    <div class="fz_18 fc_2A2A2A mt_50" v-html="item.data.content">-->
                    <div class="fz_18 fc_2A2A2A mt_50">
                      {{item.author}}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="(index % 2 == 1)">
                <el-row>
                  <el-col :span="2" style="padding: 70px 13px 136px 30px; textalign: end">
                    <img
                        src="../../assets/images/home/honor.png"
                        class="honor_img"
                    />
                  </el-col>
                  <el-col :span="10" style="padding: 60px 0">
                    <div class="fz_36 fc_2A2A2A">
                      {{ item.title }}
                    </div>
<!--                    <div class="fz_18 fc_2A2A2A mt_50" v-html="item.data.content">-->
                    <div class="fz_18 fc_2A2A2A mt_50">
                      {{item.author}}
                    </div>
                  </el-col>
                  <el-col :span="2"><div style="color: #fff">aaa</div></el-col>
                  <el-col :span="10" style="padding: 60px 0">
                    <div class="text-center">
                      <div class="bg_horizontal">
<!--                        <el-image class="vertical_img" :src="item.img" :preview-src-list="[item.img]">-->
                        <el-image class="vertical_img" :src="item.img">
                        </el-image>
                      </div>
                      <div class="fc_595959 fz_18 mt_30 text-center">
                        {{ item.title }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else v-html="content" class="pd_30 detail_content"></div>
  </div>
</template>
<script>
import { getSinglePage, saveContact, getNewsPage,getNewsById } from '@/api/home';
import SIdentify from '@/components/identify';
import Map from "@/components/map/index"
export default {
  props: {
    title: {
      type: String
    },
    id: {
      type: [String, Number]
    }
  },
  data() {
    return {
      contactForm: {
        telephone: '',
        name: '',
        title: '',
        content: ''
      },
      identifyCode: '',
      identifyCodes: '1234567890',
      inputCode: '',
      content: '',
      ryzz: [],
      idChildren: {},
      honorsList: [],
      honorsList2: [],
    };
  },
  components: {
    's-Identify': SIdentify,
    Map
  },
  watch: {
    id() {
      this.getPageList();
    },
    identifyCode(v) {
      // this.form1.code = v
      this.code = v;
    },
    title(newVal, oldVal) {
      console.log("新值",newVal);
      console.log("旧值",oldVal);
      // if(newVal == "荣誉资质") {
      //
      // }
    }
  },
  created() {
    this.refreshCode();
    // const self = this;
    // self.idChildren = JSON.parse(sessionStorage.getItem("idChildren"))[0]
    // console.log(self.idChildren);
    // this.getEnvironmentList(self.idChildren.id)
  },
  mounted() {
    if (this.id) {
      this.getPageList();
    }
    const self = this;
    self.identifyCode = '';
    self.makeCode(this.identifyCodes, 4);
    if(sessionStorage.getItem("index_list")) {
      console.log(JSON.parse(sessionStorage.getItem("index_list")));
      self.ryzz = JSON.parse(sessionStorage.getItem("index_list")).ryzz
      console.log(self.ryzz);
    }
    self.idChildren = JSON.parse(sessionStorage.getItem("idChildren"))[0]
    console.log(self.idChildren);
    this.getEnvironmentList(self.idChildren.id)
  },
  methods: {
    getEnvironmentList(listId) {
      const obj = {
        id: listId,
        current: 1,
        size: 10
      };
      getNewsPage(obj).then((res) => {
        this.honorsList2 = res.data.records
        console.log('honorsList2', this.honorsList2);
        res.data.records.forEach( (item,index) => {
          getNewsById(item.id).then( res => {
            this.honorsList[index] = res
          })
        })
        console.log("honorsList", this.honorsList);
      });
    },
    getPageList() {
      this.content = '';
      getSinglePage(this.id).then((res) => {
        console.log("getSinglePage", res);
        if(res.data.content) {
          this.content = res.data.content;
        }
      });
    },
    // 图形验证码更换
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    handleSend() {
      if (
        !this.contactForm.name ||
        !this.contactForm.title ||
        !this.contactForm.telephone
      ) {
        this.$message.warning('请输入主题、联系人、电话');
        return;
      }
      if (this.inputCode != this.code) {
        this.$message.warning('验证码错误！');
        this.inputCode = '';
        this.refreshCode();
        return;
      }
      const obj = {
        ...this.contactForm
      };
      saveContact(obj).then(() => {
        this.$message.success('提交成功');
        this.contactForm = {
          telephone: '',
          name: '',
          title: '',
          content: ''
        };
        this.inputCode = '';
        this.refreshCode();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  height: 515px;
}
::v-deep .el-input-group__prepend {
  width: 60px;
  background-color: #fff;
  padding-right: 0;
}
::v-deep .el-input__inner {
  border-left: 0;
}
::v-deep .el-input-group__append {
  background: #fff;
  padding: 0;
}
.primary-button {
  width: 160px;
  height: 46px;
  background: #4690f7;
  border-radius: 4px;
}
.detail_content {
  ::v-deep {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.right_container {
  width: 100%;
  padding: 60px 100px 131px 86px;
}
.fc_2A2A2A {
  color: #2a2a2a;
}
.mt_50 {
  margin-top: 50px;
}
.bg_horizontal {
  width: 430px;
  height: 310px;
  //background: #8b8b8b;
  background: #fff;
  border: 10px solid #ffffff;
  //box-shadow: 0px 16px 10px 0px rgba(0, 0, 0, 0.1);
}
.vertical_img {
  width: auto;
  height: 100%;
  box-shadow: 0px 16px 10px 0px rgba(0, 0, 0, 0.1)
}
.fc_595959 {
  color: #595959;
}
.fc_2A2A2A {
  color: #2a2a2a;
}
</style>