<template>
  <div class="bg_F8F8F8">
    <Header :activeName="activeName" />
    <div class="mt_69">
      <img :src="info.img" class="about_image" />
      <div class="flex justify-center">
        <div class="fixed_width bg_FFFFFF header_box">
          <div class="title">{{ title }}</div>
          <div class="describe">{{ english }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="fixed_width mt_20 home_title ml_20">首页>{{ title }}</div>
    </div>
    <div class="flex justify-center mt_20 pb_60">
      <div class="fixed_width flex">
        <div class="left_container bg_FFFFFF">
          <el-menu
            :default-active="activeMenuName"
            class="el-menu-vertical-demo"
            @select="handleSelect"
          >
            <el-menu-item-group v-for="item in info.children" :key="item.id">
              <el-menu-item :index="item.title">{{ item.title }}</el-menu-item>
            </el-menu-item-group>
          </el-menu>
        </div>
        <div class="ml_15 right_container bg_FFFFFF">
          <About :id="id" :title="title"/>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Header from '@/components/header/index';
import About from '../menu/about';
import Bottom from '@/components/bottom/index';
import { getInfo } from '@/api/home';
export default {
  components: {
    Header,
    About,
    Bottom
  },
  data() {
    return {
      activeName: '关于我们',
      info: '',
      activeMenuName: '',
      title: '',
      id: '',
      english: ''
    };
  },
  watch: {
    '$route.query.activeName'(newVal,oldVal) {
      console.log(newVal,oldVal)
      this.activeMenuName = newVal
      this.getInfo()
    }
  },
  mounted() {
    if(sessionStorage.getItem('info')) {
      this.info = JSON.parse(sessionStorage.getItem('info'));
      console.log("69info", this.info);
      console.log(this.$route.query.activeName);
      if(this.$route.query.activeName) {
        this.activeMenuName = this.$route.query.activeName
      } else {
        this.activeMenuName = this.info.children[0].title;
      }
      
      this.info.img = this.info.img
        ? this.info.img
        : require('@/assets/images/about.jpg');
      this.info.children.forEach((item) => {
        if (item.title == this.activeMenuName) {
          this.id = item.id;
          this.title = item.title;
          this.english = item.english;
        }
      });
    } else {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      getInfo().then((res) => {
        // res.data.list_spt.forEach((item) => {
        //   item.type = 'page';
        // });
        console.log("getInfo", res);
        this.tab_list = res.data.list_menu;
        // res.data.list_nt.forEach((item) => {
        //   item.type = 'news';
        //   this.tab_list.push(item);
        // });
        this.tab_list.forEach((item) => {
          if(item.title == '关于我们') {
            this.info = item
          }
        });
        this.info.img = this.info.img
          ? this.info.img
          : require('@/assets/images/about.jpg');
        if(this.$route.query.activeName) {
          this.activeMenuName = this.$route.query.activeName
        } else {
          this.activeMenuName = this.info.children[0].title;
        }
        this.info.children.forEach((item) => {
          if (item.title == this.activeMenuName) {
            this.id = item.id;
            this.title = item.title;
            this.english = item.english;
          }
        });
      });
    },
    handleSelect(key) {
      this.english = '';
      this.info.children.forEach((item) => {
        if (item.title == key) {
          this.id = item.id;
          this.title = key;
          this.english = item.english;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.about_image {
  width: 100%;
  height: 411px;
}
.header_box {
  height: 120px;
  margin-top: -60px;
}
.title {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #2c8eff;
  padding: 22px 0 0 62px;
}
.describe {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #e3e3e3;
  padding-left: 62px;
}
.home_title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #111c2f;
}
.ml_15 {
  margin-left: 15px;
}
.left_container {
  width: 200px;
  font-weight: 500;
}
.right_container {
  width: 100%;
}
.el-menu {
  border-right: 0;
  min-width: 175px;
}
::v-deep .el-menu-item-group__title {
  padding: 0;
}
.el-menu-item {
  text-align: center;
  // padding-left: 54px !important;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
}
.el-menu-item.is-active {
  background: #f4f6fe;
  opacity: 0.7;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4690f7;
  border-right: 4px solid #4690f7;
}
</style>